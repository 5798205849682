import Centrifuge from 'centrifuge'
import { action, observable } from 'mobx'

export class WSStore {
  centrifuge: any
  @observable isEnabled: boolean = false


  constructor() {
    const uri = process.env.STAGE === 'prod' ? 'centrifugo.bettings.ch:7010' : '136.243.177.215:7070'
    const wsUri = `${window.origin.indexOf('https').toString() === '-1' ? 'ws' : 'wss'}://${uri}/connection/websocket`
    this.centrifuge = wsUri && new Centrifuge(wsUri)
  }

  public start() {
    if (!this.isEnabled) {
      this.centrifuge.connect()
      this.centrifuge.on('disconnect', (resp: any) => {
        this.setWsStatus(false)
      })
      this.centrifuge.on('connect', (resp: any) => {
        this.setWsStatus(true)
      })
    }
  }

  @action
  setWsStatus(status: boolean) {
    this.isEnabled = status
  }

  public subscribeChanel(channel: string, onEvent: any) {
    return this.centrifuge.subscribe(channel, (resp: any) => {
      onEvent(resp)
    }).on('join', (message: any) => {
      console.log('Client joined channel', message)
    }).on('leave', (message: any) => {
      console.log('Client left channel', message)
    })
  }
}
