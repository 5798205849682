import * as React from 'react'
import {render} from 'react-dom'
import {ConfigStore} from 'core/config'
import {App} from 'modules/app.module'
import {ProjectLoader} from 'core/project-loader'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'

async function paint() {
  const stage: Stage = process.env.STAGE ? (process.env.STAGE as Stage) : 'dev'
  const configStore = new ConfigStore(stage, process.env.DOMAIN)
  await configStore.load()

  const projectLoader = new ProjectLoader(configStore)
  await projectLoader.load()

  render(<App {...projectLoader.app} />, document.getElementById('root') || document.body)
}

paint()
// serviceWorkerRegistration.register()
