import { useContext } from 'react'
import { useParams } from 'react-router'

import { AppContext } from 'core'

type TransactionStatus = '__hold' | '__canceled' | '__accepted' | '__error' | '__wait_approve'

export const PaymentResult = () => {
  const { notifier, history, http, i18n } = useContext(AppContext)
  const { id } = useParams()
  const loc = window.location.href
  if (loc.includes('triction') || loc.includes('shoppingboltz')) {
    window.location.href = 'https://24betting.com/'
  }
  if (loc.includes('bigshopkart')) {
    window.location.href = 'https://india24bet.com/'
  }
  if (loc.includes('bigshopkart')) {
    window.location.href = 'https://iword247.com/'
  }
  if (loc.includes('gocrazyrummy')) {
    window.location.href = 'http://betindialive.com/'
  }
  http.wst
    .get<{ status: TransactionStatus }>(`/payment-transactions/${id}`)
    .then(({ data }) => {
      const result = i18n.t(`t.transaction_${data.status}`)

      if (data.status !== '__error') {
        notifier.success(result)
      } else {
        notifier.error(result)
      }

      history.push('/')
    })
    .catch(() => {
      history.push('/')
    })

  return null
}
