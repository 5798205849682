import {ConfigStore} from '../core'
export const MainChoiseKey = 'MainChoiseKey'
export class Betting24Helper {
    static usedSports = [
        'cricket',
        'soccer'
    ]

    static usedLeagues = [
        'UEFA Soccer'
    ]

    static usedSubLeague = [
        'UEFA Euro 2020'
    ]
}

export const saveChoise = (type: string) => {
  if (localStorage.getItem(MainChoiseKey)) {
    return
  }
  switch (type) {
    case 'casino':
      localStorage.setItem(MainChoiseKey, 'casino')
      break
    case 'exchange':
      localStorage.setItem(MainChoiseKey, 'exchange')
      break
    case 'sportbook':
      localStorage.setItem(MainChoiseKey, 'sportbook')
      break
    default:
      break
  }
}

export const is24Betting = (config: ConfigStore, only24 = false) => {
    const { project } = config.data
    return only24 ? project === '24betting' : project === '24betting' || project === 'realwin' || project === '24bettingnewdis'
}

